<template>
    <div>
        <loader v-if="showloader"></loader>
        <h3 v-if="show_alert && status">
            Success
        </h3>
        <h3 v-if="show_alert && !status">
            Failed
        </h3>
    </div>
</template>

<script>
import loader from "@/components/Loader";
import config from '@/library/Config.js'
import cookie from "js-cookie";

export default {
    components: {loader},
    data: () => ({
        showloader:true,
        show_alert:false,
        status:'',
    }),
//     systemDomain(){
//       return config.SYSTEM_HOST;
//    },
    mounted() {
        console.log("here")
        if(Object.keys(this.$route.query).length){
            let data = this.$route.query
            if(data.type == 1){
                data.q == 'su' ? this.status=true : this.status=false 
                this.verifyEsewaPayment(data);            
            }else if(data.type == 2){
                // this.verifyKhaltiPayment(data);
            }else{
                this.verifyFonePayPayment(data);
            }
        }

    },

    methods:{
        verifyEsewaPayment(data){
            this.$rest.post('/esewa_payment_success',data)
            .then((res)=>{
                if(res.status == 200){

                    this.show_alert=true
                    this.showloader = false
                    this.$storage.sessionRemove('_payment_alert')
                    // cookie.remove('_payment_alert')
                }
            })
            .catch((error)=>{

            })
        },
        verifyKhaltiPayment(data){
            this.$rest.post('/khalti-verification',data)
            .then((response)=>{
                if(response.status == 200){
                    this.status = true;
                    this.show_alert=true;
                    this.showloader = false
                    // cookie.remove('_payment_alert')
                    this.$storage.sessionRemove('_payment_alert')
                }
            }).catch((error)=>{
                    this.status = false;
                    this.show_alert=false;
                    this.showloader = false
            })
        },
        verifyFonePayPayment(){

        }
    }
}
</script>